let mode = 'all';
let currentChords;
let currentSpotify;
let currentCapo;
let currentStrum;
let currentBeatsPerBar;
let isStopped = true;
const $h1 = document.querySelector('h1');
const $home = document.querySelector('.home-page');
const $track = document.querySelector('.track-page');
const $footer = document.querySelector('footer');
const el = {
  all: document.getElementById('all'),
  chords: document.getElementById('chords'),
  lyrics: document.getElementById('lyrics'),
};

document.getElementById('home').addEventListener('click', () => {
  goHome();
});

const $playStopButton = document.getElementById('play-stop');
$playStopButton.addEventListener('click', () => {
  if (isStopped) {
    isStopped = false;
    $playStopButton.innerText = 'Stop';
    if (currentSpotify) playSpotify();
    else playAllChords();
  } else {
    isStopped = true;
    $playStopButton.innerText = 'Play';
    pauseSpotify();
  }
});

function toggleView(newMode) {
  el.all.classList.remove('selected');
  el.chords.classList.remove('selected');
  el.lyrics.classList.remove('selected');

  el[newMode].classList.add('selected');
}
document.getElementById('toggle').addEventListener('click', () => {
  if (mode === 'all') mode = 'chords';
  else if (mode === 'chords') mode = 'lyrics';
  else mode = 'all';
  toggleView(mode);
});
function getCapo(n) {
  currentCapo = n | 0;
  if (!n || n === 0) return '';
  if (n === 1) return 'Capo: 1<sup>st</sup> fret';
  if (n === 2) return 'Capo: 2<sup>nd</sup> fret';
  if (n === 3) return 'Capo: 3<sup>rd</sup> fret';
  return `Capo: ${n}<sup>th</sup> fret`;
}

$home.addEventListener('click', (e) => {
  if (e.target.dataset && e.target.dataset.file) {
    history.pushState(
      e.target.dataset.file,
      '',
      `?track=${e.target.dataset.file}`
    );
    loadTrack(e.target.dataset.file);
  }
});

// Handle forward/back buttons
window.addEventListener('popstate', (event) => {
  const urlParams = new URLSearchParams(event.target.location.search);
  let possibleTrack = urlParams.get('track');
  // If a state has been provided, we have a "simulated" page
  // and we update the current page.
  if (possibleTrack > 0) {
    // Simulate the loading of the previous page
    loadTrack(possibleTrack);
  } else {
    displayHome();
  }
});

function displayHome() {
  $track.style.display = 'none';
  $footer.style.display = 'none';
  $home.style.display = 'block';
  $h1.innerText = 'Pick your tab';
  document.querySelector('.artist').innerText = '';
  document.querySelector('.strum').innerText = '';
}

function goHome() {
  history.pushState(null, '', '/');
  displayHome();
}

function loadTrack(filename) {
  el.chords.innerHTML = '';
  el.all.innerHTML = '';
  el.lyrics.innerHTML = '';
  fetch(`${filename}.json`)
    .then((x) => x.json())
    .then(
      ({
        title,
        artist,
        chords,
        lyrics,
        song,
        spotify,
        beatsPerBar,
        capo,
        strum,
      }) => {
        currentChords = [];
        currentSpotify = spotify;
        currentBeatsPerBar = beatsPerBar;
        currentStrum = strum;
        document.querySelector('h1').innerText = title;
        document.querySelector('.artist').innerText = artist;
        document.querySelector('.strum').innerText =
          'Strum: ' + strum.replace(/D/g, '↓').replace(/U/g, '↑');
        document.querySelector('.capo').innerHTML = getCapo(capo);
        const justChords = [];
        song.forEach((part) => {
          const l = lyrics[part.w];
          const c = chords[part.c];
          const justLyrics = [];
          let justChordLine = document.createElement('div');
          justChordLine.classList.add('bar-line');
          for (let i = 0; i < (l ? l.length : c.length); i++) {
            const chordIndex = i % c.length;
            if (i > 0 && chordIndex === 0) {
              el.chords.appendChild(justChordLine);
              justChordLine = document.createElement('div');
              justChordLine.classList.add('bar-line');
            }
            const bar = document.createElement('div');
            bar.classList.add('bar');
            const justChordsBar = document.createElement('div');
            justChordsBar.classList.add('bar');
            const chord = document.createElement('div');
            chord.classList.add('chord');
            const chord2 = document.createElement('div');
            chord2.classList.add('chord');
            const barText = [];
            for (let j = 1; j <= beatsPerBar; j++) {
              if (c[chordIndex][`c-${j}`]) {
                barText.push(c[chordIndex][`c-${j}`]);
                currentChords.push({
                  chord: c[chordIndex][`c-${j}`],
                  beats: 1,
                });
              } else {
                barText.push('.');
                currentChords[currentChords.length - 1].beats += 1;
              }
            }
            chord.innerText = barText.join(' ');
            chord2.innerText = barText.join(' ');
            bar.appendChild(chord);
            if (l) {
              const lyricDiv = document.createElement('div');
              lyricDiv.classList.add('lyrics');
              if (typeof l[i] === 'string') {
                lyricDiv.innerText = l[i];
                justLyrics.push(l[i]);
              } else {
                let rest = l[i].b;
                const lyricText = [];
                while (rest > 1) {
                  rest--;
                  lyricText.push('𝄽 ');
                }
                lyricText.push(l[i].w);
                justLyrics.push(l[i].w);
                lyricDiv.innerText = lyricText.join('');
              }
              bar.appendChild(lyricDiv);
            }
            justChordsBar.appendChild(chord2);
            justChordLine.appendChild(justChordsBar);
            el.all.appendChild(bar);
          }

          el.chords.appendChild(justChordLine);
          const line = document.createElement('div');
          line.innerText = justLyrics.join(', ');
          el.lyrics.appendChild(line);
        });
        $track.style.display = 'flex';
        $home.style.display = 'none';
        $footer.style.display = 'block';
      }
    );
}

// playing chords
const context = new AudioContext();

// Signal dampening amount
let dampening = 0.99;

// Returns a AudioNode object that will produce a plucking sound
function pluck(frequency) {
  const pluck = context.createScriptProcessor(4096, 0, 1);
  const N = Math.round(context.sampleRate / frequency);

  // y is the signal presently
  const y = new Float32Array(N);
  for (let i = 0; i < N; i++) {
    // We fill this with gaussian noise between [-1, 1]
    y[i] = Math.random() * 2 - 1;
  }

  // This callback produces the sound signal
  let n = 0;
  pluck.onaudioprocess = function (e) {
    // We get a reference to the outputBuffer
    const output = e.outputBuffer.getChannelData(0);

    // We fill the outputBuffer with our generated signal
    for (let i = 0; i < e.outputBuffer.length; i++) {
      // This averages the current sample with the next one
      // Effectively, this is a lowpass filter with a
      // frequency exactly half of sampling rate
      y[n] = (y[n] + y[(n + 1) % N]) / 2;

      // Put the actual sample into the buffer
      output[i] = y[n];

      // Hasten the signal decay by applying dampening.
      y[n] *= dampening;

      // Counting constiables to help us read our current
      // signal y
      n++;
      if (n >= N) n = 0;
    }
  };

  // The resulting signal is not as clean as it should be.
  // In lower frequencies, aliasing is producing sharp sounding
  // noise, making the signal sound like a harpsichord. We
  // apply a bandpass centred on our target frequency to remove
  // these unwanted noise.
  const bandpass = context.createBiquadFilter();
  bandpass.type = 'bandpass';
  bandpass.frequency.value = frequency;
  bandpass.Q.value = 1;

  // We connect the ScriptProcessorNode to the BiquadFilterNode
  pluck.connect(bandpass);

  // Our signal would have died down by 2s, so we automatically
  // disconnect eventually to prevent leaking memory.
  setTimeout(() => {
    pluck.disconnect();
  }, 2000);
  setTimeout(() => {
    bandpass.disconnect();
  }, 2000);

  // The bandpass is last AudioNode in the chain, so we return
  // it as the "pluck"
  return bandpass;
}

function strum(fret, isDown) {
  const stagger = 25;

  // Reset dampening to the natural state
  dampening = 0.99;

  // Connect our strings to the sink
  const dst = context.destination;
  for (let index = 0; index < 6; index++) {
    const val = isDown ? 5 - index : index;
    if (Number.isFinite(fret[val])) {
      setTimeout(() => {
        pluck(getFrequency(val, fret[val])).connect(dst);
      }, stagger * index);
    }
  }
}

function getFrequency(string, fret) {
  // Concert A frequency
  const A = 110;

  // These are how far guitar strings are tuned apart from A
  const offsets = [-5, 0, 5, 10, 14, 19];

  return A * Math.pow(2, (fret + offsets[string]) / 12);
}

function capoed(fret) {
  return fret.map((x) => x + currentCapo);
}

function getFrets(chord) {
  if (chord === 'D') return capoed([, 0, 0, 2, 3, 2]);
  if (chord === 'Em') return capoed([0, 2, 2, 0, 0, 0]);
  if (chord === 'D/F#') return capoed([2, , 0, 2, 3, 2]);
  if (chord === 'G') return capoed([3, 2, 0, 0, 0, 3]);
  if (chord === 'A') return capoed([0, 0, 2, 2, 2, 0]);
  if (chord === 'Am') return capoed([, 0, 2, 2, 1, 0]);
  if (chord === 'F') return capoed([1, 3, 3, 2, 1, 1]);
  if (chord === 'E') return capoed([0, 2, 2, 1, 0, 0]);
  if (chord === 'B') return capoed([2, 2, 4, 4, 4, 2]);
  if (chord === 'Bb') return capoed([1, 1, 3, 3, 3, 1]);
  if (chord === 'Gm') return capoed([3, 5, 5, 3, 3, 3]);
  if (chord === 'Cm') return capoed([3, 3, 5, 5, 4, 3]);
  if (chord === 'D7') return capoed([, 0, 0, 2, 1, 2]);
  if (chord === 'C') return capoed([3, 3, 2, 0, 1, 0]);
  if (chord === 'Fmaj7') return capoed([, , 3, 2, 1, 0]);
  if (chord === 'C/B') return capoed([, 2, 2, 0, 1, 0]);
  if (chord === 'E7') return capoed([0, 2, 0, 1, 0, 0]);
  if (chord === 'G7') return capoed([3, 2, 0, 0, 0, 1]);
  if (chord === 'Fm') return capoed([1, 3, 3, 1, 1, 1]);
  if (chord === 'C#m') return capoed([4, 4, 6, 6, 5, 4]);
  if (chord === 'A(5th)') return capoed([5, 7, 7, 6, 5, 5]);
  if (chord === 'G#m') return capoed([4, 6, 6, 4, 4, 4]);
  if (chord === 'Fadd9') return capoed([, , 3, 2, 1, 3]);
}

function playAllChords() {
  playChord(0);
}

function downChord(frets) {
  context.resume().then(strum(frets));
}
function upChord(frets) {
  context.resume().then(strum(frets), true);
}
function playChordPart(strPattern, strIndex, frets, timeout, nextChordIdx) {
  if (isStopped) {
    return;
  }
  if (strPattern[strIndex] === 'D') downChord(frets);
  else if (strPattern[strIndex] === 'U') upChord(frets);
  setTimeout(() => {
    if (strIndex === strPattern.length - 1) {
      playChord(nextChordIdx);
    } else {
      playChordPart(strPattern, strIndex + 1, frets, timeout, nextChordIdx);
    }
  }, timeout);
}

function playChord(idx) {
  console.log(currentChords[idx]);

  let strumPattern = currentStrum.substring(
    0,
    (currentStrum.length * currentChords[idx].beats) / currentBeatsPerBar
  );
  console.log(strumPattern);
  const frets = getFrets(currentChords[idx].chord);
  console.log(frets);

  playChordPart(strumPattern, 0, frets, 200, idx + 1);
}

const generateRandomString = (length) => {
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const values = crypto.getRandomValues(new Uint8Array(length));
  return values.reduce((acc, x) => acc + possible[x % possible.length], '');
};
const sha256 = async (plain) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
};

const base64encode = (input) => {
  return btoa(String.fromCharCode(...new Uint8Array(input)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};

const clientId = '9ea7d720f6494f4c9189ee3773c876b2';
const redirectUri = window.location.origin;
const scope =
  'streaming user-modify-playback-state user-read-private user-read-email';
const authUrl = new URL('https://accounts.spotify.com/authorize');

// document
//   .getElementById('play-spotify')
//   .addEventListener('click', async () => {

async function startOAuth() {
  const codeVerifier = generateRandomString(64);
  const hashed = await sha256(codeVerifier);
  const codeChallenge = base64encode(hashed);

  // generated in the previous step
  window.localStorage.setItem('code_verifier', codeVerifier);

  const params = {
    response_type: 'code',
    client_id: clientId,
    scope,
    code_challenge_method: 'S256',
    code_challenge: codeChallenge,
    redirect_uri: redirectUri,
  };

  authUrl.search = new URLSearchParams(params).toString();
  window.location.href = authUrl.toString();
}

const getToken = async (code) => {
  // stored in the previous step
  let codeVerifier = localStorage.getItem('code_verifier');

  const payload = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      client_id: clientId,
      grant_type: 'authorization_code',
      code,
      redirect_uri: redirectUri,
      code_verifier: codeVerifier,
    }),
  };

  const body = await fetch('https://accounts.spotify.com/api/token', payload);
  const response = await body.json();

  isGettingToken = false;

  localStorage.setItem('access_token', response.access_token);
  createPlayer(response.access_token);
};

function playSpotify() {
  const deviceId = localStorage.getItem('device_id');
  const accessToken = localStorage.getItem('access_token');
  const payload = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uris: [currentSpotify],
    }),
  };
  fetch(
    `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`,
    payload
  );
}

function pauseSpotify() {
  const deviceId = localStorage.getItem('device_id');
  const accessToken = localStorage.getItem('access_token');
  const payload = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };
  fetch(
    `https://api.spotify.com/v1/me/player/pause?device_id=${deviceId}`,
    payload
  );
}
var isPlayerReady = false;
var isGettingToken = false;
const urlParams = new URLSearchParams(window.location.search);
let code = urlParams.get('code');
let possibleTrack = urlParams.get('track');
if (possibleTrack) {
  loadTrack(possibleTrack);
} else if (code) {
  isGettingToken = true;
  urlParams.delete('code');
  const state = urlParams.toString();
  history.pushState(null, '', `${state.length > 0 ? `?${state}` : '/'}`);
  getToken(code);
}
const script = document.createElement('script');
script.src = 'https://sdk.scdn.co/spotify-player.js';
script.async = true;

document.body.appendChild(script);

window.onSpotifyWebPlaybackSDKReady = async () => {
  isPlayerReady = true;
  if (!isGettingToken) {
    const accessToken = await testAccessToken();
    if (!accessToken) startOAuth();
    else {
      createPlayer(accessToken);
    }
  }
};

async function testAccessToken() {
  const accessToken = localStorage.getItem('access_token');
  if (!accessToken) return false;
  return await fetch('https://api.spotify.com/v1/me', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((x) => x.json())
    .then((json) => {
      if (json.error) return false;
      return accessToken;
    })
    .catch((err) => {
      return false;
    });
}

function createPlayer(accessToken) {
  const player = new Spotify.Player({
    name: 'Web Playback SDK Quick Start Player',
    getOAuthToken: (cb) => {
      cb(accessToken);
    },
    volume: 0.5,
  });
  // Ready
  player.addListener('ready', ({ device_id }) => {
    console.log('Ready with Device ID', device_id);
    localStorage.setItem('device_id', device_id);
  });

  // Not Ready
  player.addListener('not_ready', ({ device_id }) => {
    console.log('Device ID has gone offline', device_id);
  });

  player.addListener('initialization_error', ({ message }) => {
    console.log('1', message);
  });

  player.addListener('authentication_error', ({ message }) => {
    console.log('2', message);
  });

  player.addListener('account_error', ({ message }) => {
    console.log('3', message);
  });

  player.connect();
}
